<template>
  <f7-page>
    <!-- <navbar title="关于" :back-link="false"></navbar> -->
    <f7-navbar title="中信银行" back-link="  " sliding>
      <f7-nav-right>
        <f7-link icon="icon-bars" open-panel="left"></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="test1"></div>
    <div class="test2">
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>

    </div>
    <div class="test3"></div>
    <div class="test2">
      <p>123</p><p>123</p><p>123</p><p>123</p>
    </div>
    <div class="test3"></div>
    <div class="test2"><p>123</p><p>123</p><p>123</p><p>123</p><p>123</p></div>
    <div class="test3"></div>
    <div class="test2"><p>123</p><p>123</p></div>
    <div class="test3"></div>
    <div class="test2"><p>123</p></div>
    <div class="test3"></div>
  </f7-page>
</template>

<script>
export default {
  name: "about",
  setup() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.test1{
  left:0%;
  right:0%;
  top:0%;
  height: 10%;
  background: aqua;
}
.test2{
  left:0%;
  right:0%;
  // top:10%;
  // height: 20%;
  background: pink;
}
.test3{
  left:0%;
  right:0%;
  // top:10%;
  height: 40%;
  background: green;
}
</style>
